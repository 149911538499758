.title{
  padding-top: 5%;
  text-align: center;
}
#widget-container {
  border:solid 1px black;
  box-shadow: 2px 2px 5px;
  margin: auto;
  margin-top: 30px;
  height: 33.33%;
  width: 33.33%;
}